














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MemberActivation extends Vue {
  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public info: any = {
    username: '',
    lastname: '',
    email: '',
  };
  public error: string = '';
  public password: string = '';
  public confirmPassword: string = '';

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    const token: string = this.$route.query.token;
    this.loading = true;
    this.$store.dispatch('loadMemberActivationInformation', {
      token,
    }).then((response: any) => {
      this.info = response;
      this.loading = false;
    }).catch((error: any) => {
      this.$router.push(`/`);
    });
  }

  private handleSubmit() {
    const token: string = this.$route.query.token;
    const password: string = this.password;
    const email: string = this.info.email;
    this.loading = true;
    this.$store.dispatch('activateMember', {
      payload: {
        token, email, password,
      },
    }).then((response: any) => {
      this.info = response;
      this.loading = false;
      this.$store.dispatch('logout');
      this.$store.dispatch('login', {
        email, password,
      }).catch((error: any) => {
        this.error = 'Email ou mot de passe invalide';
        this.loading = false;
      });
    }).catch((error: any) => {
      this.loading = false;
      this.error = error.message;
    });
  }

}
